<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <!-- <p class="text-caption text-end">
                <v-btn text @click="cancel">Cancel</v-btn>
            </p> -->
            <CheckoutProgress step="payment" class="mb-6"/>
            <v-card v-if="status">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>{{ titleText }}</v-app-bar-title>
                </v-app-bar>
                <v-card-text v-if="status">
                    <p v-if="status === 'not_found'">Not Found</p>
                    <p v-else-if="status === 'requires_payment_method'">Continue to checkout</p>
                    <p v-else-if="status === 'requires_confirmation'">Requires confirmation</p>
                    <p v-else-if="status === 'requires_action'">Requires action</p>
                    <p v-else-if="status === 'processing'">Processing</p>
                    <p v-else-if="status === 'succeeded'">
                        Continue to <router-link :to="finalizeLink">complete your order</router-link>.
                    </p>
                    <p v-else-if="status === 'canceled'">Payment canceled</p>
                    <p v-else>
                        We ran into something unexpected with your order.
                        Please contact customer support.
                        Status code: {{ status }}.
                    </p>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer/>
                    <v-btn :style="primaryButtonStyle" @click="checkout">Continue</v-btn>
                </v-card-actions> -->
            </v-card>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && error">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable.</p>
                    <!-- TODO: We are going to automatically retry until it's ready. Please wait... -->
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove hint area from item selection checkbox */
.v-input.v-input--checkbox.checkout-item-selection .v-messages {
    display: none;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { toMillis } from '@libertyio/time-util-js';
// import { loadStripe } from '@stripe/stripe-js';
// import { isValidEmail } from '@/sdk/input';
import CheckoutProgress from '@/components/CheckoutProgress.vue';

export default {
    components: {
        CheckoutProgress,
    },
    data: () => ({
        status: null,
        product: null,
        isViewReady: false,
        cart: null,
        email: null,
        emailError: null,
        emailErrorTimeout: null,
        checkoutTimestamp: null,
        error: false,
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
        receiptLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-receipt', params: { brandprofile: this.brandprofile }, query: { payment_intent: this.$route.query.payment_intent } };
            } else {
                link = { name: 'main-receipt', query: { payment_intent: this.$route.query.payment_intent } };
            }
            return link;
        },
        finalizeLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-finalize', params: { brandprofile: this.brandprofile }, query: { payment_intent: this.$route.query.payment_intent } };
            } else {
                link = { name: 'main-checkout-finalize', query: { payment_intent: this.$route.query.payment_intent } };
            }
            return link;
        },
        titleText() {
            if (!this.status) {
                return '...';
            }
            let title;
            switch (this.status) {
            case 'not_found':
                title = 'Not found';
                break;
            case 'requires_payment_method':
                title = 'Payment required';
                break;
            case 'requires_confirmation':
                title = 'Confirm payment';
                break;
            case 'requires_action':
                title = 'Action required';
                break;
            case 'processing':
                title = 'Processing your order';
                break;
            case 'succeeded':
                title = 'Payment accepted';
                break;
            case 'canceled':
                title = 'Canceled';
                break;
            default:
                title = 'Please check your order';
                break;
            }
            return title;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.checkPaymentStatus();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.checkPaymentStatus();
            }
        },
    },
    methods: {
        async checkPaymentStatus() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { checkPaymentStatus: true });
                const payment = {
                    payment_intent: this.$route.query.payment_intent,
                    // payment_intent_client_secret: this.$route.query.payment_intent_client_secret,
                    // redirect_status: this.$route.query.redirect_status,
                };
                const response = await this.$client.site(this.brandprofile).cart.checkPaymentStatus(payment);
                if (response?.status) {
                    if (response.status === 'succeeded') {
                        /*
                        // payment succeeded, so redirect user to collect delivery info for the order
                        this.$nav.push(this.finalizeLink);
                        */
                        // payment succeeded, so redirect user to the receipt
                        this.$nav.push(this.receiptLink);
                    } else {
                        // any other status, stay here and show the user what's going on
                        this.status = response.status;
                    }
                } else {
                    this.error = true;
                }
            } catch (err) {
                console.error('checkPaymentStatus failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkPaymentStatus: false });
                this.isViewReady = true;
            }
        },
        formatAmount(currency, amount) {
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.checkPaymentStatus();
        }
    },
};
</script>
